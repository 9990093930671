import {
  handleApplicationCreatedEvent,
  handleApplicationScoreCardGroupResultsFinishedEvent,
  handleApplicationScoreCardResultsFinishedEvent,
  handleDataOrchestrationLogEvent,
  handleExternalServiceLogEvent,
  handleDocuSignEvent,
  handleExecuteDataOrchestrationBatchFinishedEvent,
  handleApplicationOffers,
  handleApplicationUpdatedEvent,
  handlePlaidEvent
} from "@/helpers/websockets/applications/handlers";
import type {
  IApplication,
  IApplicationPlaidSummary
} from "@/models/applications";
import throttle from "lodash/throttle";

export interface ExternalServiceLogEvent {
  applicationId: string;
  serviceId: string;
}

export interface DataOrchestrationLogEvent {
  applicationId: string;
  logId: string;
}

export interface DocuSignEvent {
  id: string;
  status: number;
}

export interface ApplicationCreatedEvent {
  id: IApplication;
}

export interface ApplicationScoreCardGroupResultsFinishedEvent {
  application_id: string;
}

export interface ApplicationScoreCardResultsFinishedEvent {
  application_id: string;
}

export interface OfferAcceptedEvent {
  offer_id: string;
  application_id: string;
}

export interface PlaidConnectionEvent {
  application_id: string;
  plaid: IApplicationPlaidSummary;
}

export const EXTERNAL_SERVICE_LOG_EVENT = "ExternalServiceLogEvent" as const;
export const DATA_ORCHESTRATION_SERVICE_LOG_EVENT =
  "DataOrchestrationLogEvent" as const;
export const APPLICATION_CREATED_EVENT = "ApplicationCreatedEvent" as const;
export const APPLICATION_UPDATED_EVENT =
  "Application\\ApplicationWasUpdated" as const;
export const OFFER_ACCEPTED_EVENT =
  "BorrowerPlatform\\OfferAcceptedEvent" as const;
export const APPLICATION_SCORECARD_GROUP_RESULTS_FINISHED_EVENT =
  "ScoreCardGroupResultsFinishedEvent" as const;
export const APPLICATION_SCORECARD_RESULTS_FINISHED_EVENT =
  "ScoreCardResultsFinishedEvent" as const;
export const DOCU_SIGN_EVENT = "DocuSignEvent" as const;
export const EXECUTE_DATA_ORCHESTRATION_BATCH_FINISHED_EVENT =
  "Application\\ExecuteDataOrchestrationFinished" as const;
export const PLAID_CONNECTION_EVENT = "PlaidConnectionEvent" as const;

export const APPLICATIONS_EVENT_HANDLERS = {
  [EXTERNAL_SERVICE_LOG_EVENT]: handleExternalServiceLogEvent,
  [OFFER_ACCEPTED_EVENT]: handleApplicationOffers,
  [DATA_ORCHESTRATION_SERVICE_LOG_EVENT]: handleDataOrchestrationLogEvent,
  [DOCU_SIGN_EVENT]: handleDocuSignEvent,
  [APPLICATION_SCORECARD_GROUP_RESULTS_FINISHED_EVENT]:
    handleApplicationScoreCardGroupResultsFinishedEvent,
  [APPLICATION_SCORECARD_RESULTS_FINISHED_EVENT]:
    handleApplicationScoreCardResultsFinishedEvent,
  [APPLICATION_UPDATED_EVENT]: handleApplicationUpdatedEvent,
  [PLAID_CONNECTION_EVENT]: handlePlaidEvent
} as const;

export const APPLICATION_LIST_HANDLERS = {
  [APPLICATION_CREATED_EVENT]: throttle(handleApplicationCreatedEvent, 10000),
  [EXECUTE_DATA_ORCHESTRATION_BATCH_FINISHED_EVENT]:
    handleExecuteDataOrchestrationBatchFinishedEvent
} as const;
