import { formatString } from "@/helpers/formatting";
import type { IPaginatedResponse } from "@/models/common";
import type {
  IOrchestrationTemplate,
  IDataServices
} from "@/models/orchestration";
import { axiosClient } from "../client";
import endpoints from "../endpoints";

class OrchestrationApiService {
  async getOrchestrationTemplates(params: Record<string, unknown> = {}) {
    const response = await axiosClient.get<
      IPaginatedResponse<IOrchestrationTemplate>
    >(endpoints.DATA_ORCHESTRATION_TEMPLATES.ALL, {
      params
    });
    return response.data;
  }

  async createOrchestrationTemplate(template: Partial<IOrchestrationTemplate>) {
    const response = await axiosClient.post<{ data: IOrchestrationTemplate }>(
      endpoints.DATA_ORCHESTRATION_TEMPLATES.ALL,
      template
    );
    return response.data.data;
  }

  async updateOrchestrationTemplate(
    templateId: string,
    payload: Partial<IOrchestrationTemplate>
  ) {
    const response = await axiosClient.patch<{ data: IOrchestrationTemplate }>(
      formatString(endpoints.DATA_ORCHESTRATION_TEMPLATES.SINGLE, templateId),
      payload
    );
    return response.data.data;
  }

  async deleteOrchestrationTemplate(templateId: string) {
    const response = await axiosClient.delete<{ data: { success: boolean } }>(
      formatString(endpoints.DATA_ORCHESTRATION_TEMPLATES.SINGLE, templateId)
    );
    return response;
  }

  async getOrchestrationTemplate(
    templateId: string,
    DO_SCHEMA_VERSION: string
  ) {
    const response = await axiosClient.get<{ data: IOrchestrationTemplate }>(
      formatString(endpoints.DATA_ORCHESTRATION_TEMPLATES.SINGLE, templateId),
      {
        params: { schema_version: DO_SCHEMA_VERSION }
      }
    );
    return response.data.data;
  }

  async getOrchestrationTemplateServices() {
    const response = await axiosClient.get<{ data: IDataServices }>(
      endpoints.DATA_ORCHESTRATION_TEMPLATES.SERVICES
    );
    return response.data.data;
  }

  async continuePass(appId: string, stepId: string) {
    await axiosClient.put(
      formatString(
        endpoints.APPLICATIONS.DATA_ORCHESTRATION_PASS_RESUME,
        appId,
        stepId
      )
    );
  }

  async continueFail(appId: string, stepId: string) {
    await axiosClient.put(
      formatString(
        endpoints.APPLICATIONS.DATA_ORCHESTRATION_FAIL_RESUME,
        appId,
        stepId
      )
    );
  }

  async retryStep(appId: string, stepId: string) {
    await axiosClient.put(
      formatString(
        endpoints.APPLICATIONS.DATA_ORCHESTRATION_RETRY_STEP,
        appId,
        stepId
      )
    );
  }
}

const orchestrationApiService = new OrchestrationApiService();
export default orchestrationApiService;
