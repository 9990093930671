import {
  ref,
  getCurrentInstance,
  type Ref,
  defineAsyncComponent,
  type AsyncComponentLoader,
  h,
  computed
} from "vue";
import type { Emitter } from "mitt";
import type {
  CLEAR_LF_TABLE_ROWS,
  CLOSE_SIDE_PANEL_SECTIONS,
  GO_TO_SMARTVIEW,
  ORCHESTRATION_DRAG_VAL_CHANGED,
  PLACEMENT_NO_WFB_TEMPLATE,
  SCORECARD_ATTRIBUTE_DRAG_CHANGED
} from "@/helpers/constants/events";
import type { PlacementSubmitFunder } from "@/models/funders";
import type { AxiosError } from "axios";
import type { IDoDraggable } from "@/models/orchestration";
import { useI18n } from "vue-i18n";
import { useNotification } from "@/hooks/notifications";
import Loader from "@/components/ui/Loader.vue";
import { useTippy } from "vue-tippy";
import TourStepContent from "@/components/ui/TourStepContent.vue";
import i18n from "@/i18n";
import store from "@/store";

interface PromiseWrapperOptions<FilterType> {
  filters?: FilterType;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onError?: <E extends AxiosError<any>>(error: E) => void;
  finally?: () => void;
}

/**
 * Promise Wrapper for async calls to the API
 *
 * @param callback The promiseable callback
 * @returns Wrapper containing common values and helpers
 */
export const usePromiseWrapper = <
  // Added any here because the callback is too generic
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  T extends (...params: Array<any>) => ReturnType<T>,
  FilterType extends Ref
>(
  callback: T,
  options?: PromiseWrapperOptions<FilterType>
) => {
  const loading = ref(false);
  const error = ref<unknown | null>(null);

  const { t } = useI18n();
  const { showMessage } = useNotification();

  const fetchWrapper = async (...params: Parameters<T>) => {
    let response: ReturnType<T> | undefined = undefined;
    try {
      loading.value = true;
      response = await callback(...params);
    } catch (caughtError) {
      error.value = caughtError;
      if (options?.onError) {
        options.onError(caughtError as AxiosError);
      } else {
        const data = (caughtError as AxiosError<{ message?: string }>)?.response
          ?.data;
        const message = data?.message || t("COMMON.ERROR_OCCURRED");
        showMessage(message, "error");
      }
    } finally {
      loading.value = false;
      if (options?.finally) {
        options.finally();
      }
    }
    return response as ReturnType<T> extends Promise<infer U> ? U : Awaited<T>;
  };

  const changePage = (value: number) => {
    if (!(options?.filters as Ref<{ page: number }> | undefined)?.value?.page) {
      return;
    }
    const filters = options?.filters as Ref<{ page: number }>;
    filters.value = { ...filters.value, page: value };
  };

  const changePerPage = (value: number) => {
    if (
      !(options?.filters as Ref<{ per_page: number }> | undefined)?.value
        ?.per_page
    ) {
      return;
    }
    const filters = options?.filters as Ref<{ per_page: number }>;
    filters.value = { ...filters.value, per_page: value };
  };

  return {
    loading,
    error,
    fetchWrapper,
    changePage,
    changePerPage
  };
};

export const useEmitter = () => {
  const emitter: Emitter<{
    [PLACEMENT_NO_WFB_TEMPLATE]: PlacementSubmitFunder;
    [SCORECARD_ATTRIBUTE_DRAG_CHANGED]: boolean;
    [ORCHESTRATION_DRAG_VAL_CHANGED]: IDoDraggable;
    [CLEAR_LF_TABLE_ROWS]: void;
    [CLOSE_SIDE_PANEL_SECTIONS]: string;
    [GO_TO_SMARTVIEW]: number;
  }> = getCurrentInstance()?.appContext.config.globalProperties.emitter;
  return emitter;
};

export const useTabs = <AT extends [string, ...string[]]>(
  availableTabs: AT
) => {
  const selectedTab = ref(availableTabs[0]);
  const updateTab = (val: AT[number]) => {
    if (selectedTab.value === val) {
      return;
    }
    selectedTab.value = val;
  };

  return { selectedTab, updateTab };
};

export function getAsyncComponent(loader: AsyncComponentLoader) {
  const component = defineAsyncComponent({
    loader,
    loadingComponent: () => h(Loader, { isLoading: true }),
    delay: 500,
    timeout: 5000,
    onError: () => {
      store.commit(
        "setGlobalMessage",
        { title: i18n.global.t("COMMON.ERROR_OCCURRED"), type: "error" },
        { root: true }
      );
    }
  });

  return component;
}

export interface TourStep {
  selector: string;
  title?: string;
  description?: string;
  trigger?: () => void | Promise<void>;
  placement?: NonNullable<Parameters<typeof useTippy>[1]>["placement"];
}

export interface UseTourOptions {
  steps: TourStep[];
  onTourEnd?: () => void;
}

export const useTour = ({ steps, onTourEnd }: UseTourOptions) => {
  const currentStepIndex = ref(-1);

  const currentTooltip = computed(() => {
    const step: TourStep | undefined = steps[currentStepIndex.value];
    const element = document.querySelector(step?.selector);
    if (!step || !element) {
      return null;
    }
    return useTippy(element, {
      content: h(TourStepContent, {
        title: step.title ?? "",
        description: step.description ?? "",
        currentStep: currentStepIndex.value + 1,
        totalSteps: steps.length,
        skipTour,
        nextTourStep
      }),
      hideOnClick: false,
      interactive: true,
      placement: step.placement ?? "bottom",
      theme: "tour",
      trigger: "manual"
    });
  });

  const removeCurrentStep = () => {
    // TODO: At the time of writing VueTippy is not updated to support newer versions of Vue.
    // The "destroy" method does not work as expected so we have to manually remove the content.
    // currentTooltip.value.destroy(); <-- use once VueTippy is updated
    document.querySelectorAll("[data-tippy-root]").forEach((el) => {
      const hasTourStepContent = !!el.querySelector("[data-tour-step-content]");
      if (hasTourStepContent) {
        el.remove();
      }
    });
  };

  const removeTour = () => {
    removeCurrentStep();
    currentStepIndex.value = -1;
  };

  const nextTourStep = async () => {
    removeCurrentStep();
    const nextStepIndex = currentStepIndex.value + 1;
    const nextStep = steps[nextStepIndex];

    if (nextStep) {
      await nextStep.trigger?.();
      currentStepIndex.value = nextStepIndex;
      currentTooltip.value?.show();
    } else {
      currentStepIndex.value = -1;
      onTourEnd?.();
    }
  };

  const skipTour = () => {
    removeCurrentStep();
    if (currentStepIndex.value !== -1) {
      currentStepIndex.value = -1;
    }
    onTourEnd?.();
  };

  return { nextTourStep, removeTour, skipTour };
};
