import store from "@/store";
import config from "@/config";
import { CLIENT_ADMIN, LENDFLOW_ROLE_GROUP } from "@/helpers/constants";

export const routeAccessCheck = () => {
  const user = store.state.auth.user;
  const authClientSettings = store.state.auth.authClientSettings;
  const roles = user?.roles || [];

  const canClientRedirectToBorrowerPlatform =
    authClientSettings?.is_redirect_to_borrower_platform_enabled;

  const isClientAccelerateTax =
    authClientSettings?.id === config.other.accelerateTaxClientId;

  const canUserRedirectToContractBuilder =
    (authClientSettings?.can_manage_docusign && roles.includes(CLIENT_ADMIN)) ||
    roles.some((role) => LENDFLOW_ROLE_GROUP.includes(role));

  const isClientAdmin = roles.includes(CLIENT_ADMIN);

  return {
    canClientRedirectToBorrowerPlatform,
    canUserRedirectToContractBuilder,
    isClientAccelerateTax,
    isClientAdmin
  };
};
