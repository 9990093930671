import type {
  AxiosError,
  AxiosRequestConfig,
  AxiosResponse,
  Cancel,
  InternalAxiosRequestConfig
} from "axios";
import Axios from "axios";
import router from "@/router";
import store from "@/store";
import endpoints, { requiresCSRFToken } from "./endpoints";
import config from "@/config";
import i18n from "@/i18n";
import type { ToastMessage } from "@/lib/Toast";
import { ROUTE_PAGE_NOT_FOUND } from "@/router/routes";

export type RequestConfigWithToastId = AxiosRequestConfig & {
  loadingToastId?: ToastMessage["id"];
};

export type RequestHeaders = Record<string, string | number>;
export type ErrorResponse = AxiosError<ErrorData>;
export type ErrorStatusHandlers = Record<number, (data?: ErrorData) => void>;

export type ErrorData = {
  auth_link?: string;
  message: string;
  errors: Record<string, Array<string>>;
};

const axiosClient = Axios.create({
  baseURL: config.api.baseUrl
});

const getToast = () => store.state.globalMessages;

const localeResponseMessageForMethod = (method: string | undefined) => {
  switch (method) {
    case "post":
      return "Successfully created.";
    case "put":
    case "patch":
      return "Successfully updated.";
    case "delete":
      return "Successfully deleted.";
  }
};

const localeRequestMessageForMethod = (method: string | undefined) => {
  switch (method) {
    case "post":
      return "Creating...";
    case "put":
    case "patch":
      return "Updating...";
    case "delete":
      return "Deleting...";
  }
};

const toRegExpPattern = (pattern: string) => new RegExp(pattern);

const urlPatternsToSkip = [
  "api/int/offers",
  "api/int/placements",
  "api/int/profiles/business/.*/ownership",
  "api/int/clients/.*/integration/widget",
  "api/int/clients/.*/integration/borrower_platform",
  "applications/.*/files",
  "applications/.*/offers",
  "auth",
  "data_orchestration_templates",
  "decline",
  "declined_notification",
  "filters/sort",
  "funders$",
  "mark_notes_as_read",
  "password/email",
  "plaid/token",
  "preview_email",
  "revive",
  "temporary_links",
  "score_cards/results",
  "api/int/kv",
  "api/int/filter_workflow_templates"
].map(toRegExpPattern);

const urlPatternsToSkipSuccessfulMessage = ["password/reset", "placements"].map(
  toRegExpPattern
);

const urlPatternsToSkipErrorMessage = ["placements"].map(toRegExpPattern);

const ERROR_STATUS_HANDLERS: ErrorStatusHandlers = {
  401: (data?: ErrorData) => {
    // if the 'auth_link' field is present in the data,
    // we do not need to perform any action.
    if (data?.auth_link) {
      return;
    }

    const route = router.currentRoute?.value;
    const redirectTo = route && route.name !== "Login" && route.fullPath;
    redirectTo && store.commit("setRedirectTo", redirectTo);

    void store.dispatch("auth/logout");
    void router.push({ name: "Login" });
  },
  403: () => {
    const route = router.currentRoute?.value;

    if (route.name !== ROUTE_PAGE_NOT_FOUND) {
      store.commit("setGlobalMessage", {
        title: i18n.global.t("HTTP.ERRORS.403"),
        type: "error"
      });
    }
  },
  404: (data?: ErrorData) => {
    if (data?.message) {
      store.commit("setGlobalMessage", {
        title: data.message,
        type: "error"
      });
    }
  },
  422: (data?: ErrorData) => {
    if (!data?.message) {
      return;
    }
    const errors: string[][] = Object.values(data?.errors ?? {});
    const msg =
      (errors?.[0]?.[0] as string | null | undefined) ?? "Validation failed.";
    store.commit("setGlobalMessage", { title: msg, type: "error" });
  },
  429: (data?: ErrorData) => {
    const msg = data?.message ?? "Too many requests.";
    store.commit("setGlobalMessage", { title: msg, type: "error" });
  }
};

const shouldSkipURL = (url: string | undefined, urls: Array<RegExp>): boolean =>
  urls.some((regex) => regex.test(url ?? ""));

const supportsCORSWithCredentials = (): boolean =>
  !(config.api.baseUrl ?? "").includes("localhost");

const fetchCSRFToken = async () =>
  await axiosClient.get(endpoints.CSRF_TOKEN, {
    withCredentials: supportsCORSWithCredentials()
  });

const onResponse = (response: AxiosResponse) => {
  const { method, url, loadingToastId } =
    response.config as RequestConfigWithToastId;
  if (loadingToastId) {
    // Removing the request's dedicated toast
    getToast().removeMessage(loadingToastId);
  }

  if (
    method === "get" ||
    shouldSkipURL(url, urlPatternsToSkip) ||
    shouldSkipURL(url, urlPatternsToSkipSuccessfulMessage)
  ) {
    return response;
  }

  store.commit("setGlobalMessage", {
    title: localeResponseMessageForMethod(method),
    type: method
  });

  return response;
};

const onResponseError = (error: ErrorResponse | Cancel | undefined) => {
  const { config } = error as ErrorResponse & {
    config: RequestConfigWithToastId;
  };
  if (config.loadingToastId) {
    getToast().removeMessage(config.loadingToastId);
  }
  if (error instanceof Axios.Cancel) {
    return Promise.reject({ ...error, canceled: true });
  }

  const { status, data } = error?.response ?? {};

  if (!shouldSkipURL(config.url, urlPatternsToSkipErrorMessage) && status) {
    ERROR_STATUS_HANDLERS[status]?.(data);
  }

  return Promise.reject(error);
};

// Response interceptors
axiosClient.interceptors.response.use(onResponse, onResponseError);

axiosClient.interceptors.request.use(
  async (config: InternalAxiosRequestConfig) => {
    if (!supportsCORSWithCredentials()) {
      return config;
    }

    if (
      !config.headers?.[config.xsrfHeaderName as string] &&
      requiresCSRFToken(config.url ?? "")
    ) {
      await fetchCSRFToken();
    }

    config.withCredentials = true;

    return config;
  }
);

// Request interceptor: add JWT bearer token
axiosClient.interceptors.request.use(
  (config: InternalAxiosRequestConfig) => {
    const accessToken = localStorage.getItem("accessToken");

    if (accessToken) {
      (config.headers as RequestHeaders).Authorization =
        `Bearer ${accessToken}`;
    }

    return config;
  },
  (error: ErrorResponse) => {
    return Promise.reject(error);
  }
);

// Request interceptor: for most URL's show a status toast
axiosClient.interceptors.request.use(
  (config) => {
    if (shouldSkipURL(config.url, urlPatternsToSkip)) {
      return config;
    }

    const title = localeRequestMessageForMethod(config.method);

    if (title) {
      getToast().removeAllMessagesExcept("error");
      const newMessage = getToast().addMessage({
        title,
        type: "loading",
        autoClose: false
      });
      if (newMessage?.id) {
        // The request has its own dedicated loading toast
        (config as RequestConfigWithToastId).loadingToastId = newMessage.id;
      }
    }
    return config;
  },
  (error: ErrorResponse) => {
    return Promise.reject(error);
  }
);

export { axiosClient };
