import type { Ref } from "vue";
import { computed, readonly, ref } from "vue";
import { useStore } from "vuex";
import useApplicationsStore from "@/stores/applications";
import { storeToRefs } from "pinia";
import type {
  IApplication,
  IBusiness,
  IColumnFilterGetterMap,
  ISmartFilter,
  SmartFilterColumn,
  ISmartFilterData
} from "@/models/applications";
import type { IWorkflowStage } from "@/models/workflows";
import { useAuth } from "./auth";
import {
  ADDITIONAL_TRACKING_TOKENS,
  CLIENT_TRACKING_TOKENS,
  DEALS_CLIENT_OPTIONS_UNCATEGORIZED,
  DO_SAVE_TEMPLATE_MODES,
  PHASE_ABSTRACT_APPROVED,
  PHASE_APPLICATION,
  PHASE_COMPLETE,
  PHASE_IDS_FOR_CLIENT_UNDERWRITER_AND_ER_APPS,
  PHASE_OFFER,
  PHASE_PLACEMENT,
  PHASE_UNDERWRITING,
  PHASES_FOR_LENDFLOW_FOR_FUNDING_APPS,
  SMARTVIEW_ALL_DEALS_NAME,
  WORKFLOW_CATEGORY_MARKETPLACE
} from "@/helpers/constants";
import {
  formatUsersForFilterDropdown,
  formatScorecardsForFilterDropdown
} from "@/helpers/common";
import {
  getDealCategory,
  hasBankConnection,
  isGivenCountryCanada
} from "@/helpers/deals";
import type { IOffer } from "@/models/funders";
import type { FinancialProduct, IFile } from "@/models/common";
import type { IClient } from "@/models/clients";
import type { SmartviewCategory } from "@/models/SmartviewCategory";
import { serviceHasBeenRun } from "@/helpers/services";
import type {
  IBopCommDataResponse,
  IBopStatuses
} from "@/models/commercialData/Experian";
import {
  filterNotNullableValues,
  forceNullForEmptyStrings,
  formatDateCustom,
  getDateFromMonth,
  getShortcutDate
} from "@/helpers/formatting";
import { UNDERWRITING_ATTRIBUTES_AND_SERVICES_BY_SECTION } from "@/helpers/constants/underwriting";
import type { CompanyEntity } from "@/models/commercialData/clear/ClearIdConfirmBusiness";
import type { PersonEntity } from "@/models/commercialData/clear/ClearConfirmPersonSearch";
import type { IUser } from "@/models/users";
import { DynamicDate } from "@/enums/datetime";
import type { IScorecard, IScorecardGroup } from "@/models/scorecards";
import { usePromiseWrapper } from "@/hooks/common";

// declarations
type CreateFilterData = Omit<ISmartFilter, "id" | "data"> & {
  data: Partial<ISmartFilterData>;
};

type UpdateFilterData = Omit<ISmartFilter, "data"> & {
  data: Partial<ISmartFilterData>;
};

const loadingServices = ref<Record<string, boolean>>({});

// hooks

export const useDealsBase = () => {
  const { getters } = useStore();

  const activeDeal = computed<IApplication>(
    () => getters["applications/active"]
  );

  return {
    activeDeal
  };
};

export const useDealOwners = () => {
  const { activeDeal } = useDealsBase();

  return computed(() => {
    let allOwners = activeDeal.value.business?.other_owners || [];
    const primary = activeDeal.value.personal_information;
    if (primary) {
      allOwners = [primary, ...allOwners];
    }
    return allOwners;
  });
};

export const getEntityBusinessName = (entity: CompanyEntity) => {
  if (!entity.SearchRecords) {
    return "-";
  }
  return Array.isArray(entity.SearchRecords.SearchRecord)
    ? entity.SearchRecords.SearchRecord[0].BusinessName?.["@text"] || "-"
    : entity.SearchRecords?.SearchRecord?.BusinessName?.["@text"] || "-";
};

export const getEntityPersonName = (entity: PersonEntity) => {
  if (!entity.SearchRecords) {
    return "-";
  }
  return Array.isArray(entity.SearchRecords.SearchRecord)
    ? `${entity.SearchRecords.SearchRecord[0].FirstName?.["@text"]} ${entity.SearchRecords.SearchRecord[0].LastName?.["@text"]}` ||
        "-"
    : `${entity.SearchRecords?.SearchRecord?.FirstName?.["@text"]} ${entity.SearchRecords?.SearchRecord?.LastName?.["@text"]}` ||
        "-";
};

export const useDeals = () => {
  const { getters } = useStore();
  const applicationsStore = useApplicationsStore();
  const {
    isClientUnderwriter,
    isClientAdmin,
    isClientUser,
    isClient,
    isLendflowUser,
    isAdmin
  } = useAuth();

  const { activeDeal } = useDealsBase();

  const serviceStatuses = computed(
    () => activeDeal.value?.commercialData?.statuses
  );

  const authClientSettings = computed<IClient>(
    () => getters["auth/authClientSettings"]
  );

  const client = computed(() => activeDeal.value?.partner?.client || null);

  const isUnderwritingEnabled = computed(() => {
    return !!client.value?.can_create_underwriter;
  });

  const isActiveDealSelfFunded = computed(() => {
    const client = activeDeal.value?.partner?.client || null;
    return isUnderwritingEnabled.value && client?.has_linked_funder;
  });

  const isClientFunded = computed(() => {
    return activeDeal.value?.is_client_funded;
  });

  const isActiveDealCanadian = computed(() => {
    const country = activeDeal.value.business?.address?.country?.toLowerCase();
    return isGivenCountryCanada(country);
  });

  const isActiveDealEquipmentRental = computed(
    () => activeDeal.value.is_equipment_rental
  );

  const isWorkflowLive = computed(
    () => !!activeDeal.value.workflow_template_id
  );
  const isActiveDealDataCapture = computed(
    () => !!activeDeal.value?.is_data_capture
  );

  const isStepFinished = (step: string) => {
    const primaryOwner = { ...activeDeal.value?.personal_information };
    const otherOwners = activeDeal.value?.business.other_owners || [];
    switch (step) {
      case "Prequal":
        return !!activeDeal.value?.prequalification?.prequalification_id;
      case "Business Information":
        return !!activeDeal.value?.business?.business_legal_name;
      case "Personal Information":
        return !!activeDeal.value?.personal_information?.email_address;
      case "Signed Application":
        return !![primaryOwner, ...otherOwners].every(
          (owner) =>
            owner.signed_docs?.filter((doc) => doc.signature_status === 2)
              ?.length
        );
      case "Bank Data":
        return hasBankConnection(activeDeal.value);
      case "Discovery":
        return activeDeal.value.is_discovery_complete;
      case "KYC":
        return (
          !!activeDeal.value?.commercialData?.ekata ||
          !!activeDeal.value?.commercialData?.socure.kyc ||
          serviceHasBeenRun(
            serviceStatuses.value?.ekata,
            serviceStatuses.value?.socure.kyc
          ) ||
          serviceHasBeenRun(
            serviceStatuses.value?.clear["clear_risk_inform_person_report"]
          ) ||
          serviceHasBeenRun(
            serviceStatuses.value?.clear["clear_risk_inform_person_search"],
            serviceStatuses.value?.lexis_nexis.kyc,
            serviceStatuses.value?.lexis_nexis.kyc_report
          )
        );
      case "Accounting":
        return (
          activeDeal.value?.business?.is_railz_linked ||
          activeDeal.value?.business?.is_codat_linked
        );
      case "KYB":
        return (
          serviceHasBeenRun(serviceStatuses.value?.middesk) ||
          serviceHasBeenRun(
            serviceStatuses.value?.clear["clear_risk_inform_business_report"]
          ) ||
          serviceHasBeenRun(
            serviceStatuses.value?.clear["clear_risk_inform_business_search"],
            serviceStatuses.value?.lexis_nexis.kyb_search,
            serviceStatuses.value?.lexis_nexis.kyb_report
          )
        );
      case "Litigation":
        return (
          !!activeDeal.value.commercialData?.clear?.clear_court_search ||
          serviceHasBeenRun(serviceStatuses.value?.clear?.clear_court_search) ||
          !!activeDeal.value.commercialData?.clear
            ?.clear_risk_inform_person_search ||
          serviceHasBeenRun(
            serviceStatuses.value?.clear?.clear_risk_inform_person_search
          )
        );
      case "Match":
        return (
          !!activeDeal.value?.commercialData?.sentilink_ssn_completion ||
          !!activeDeal.value?.commercialData?.sentilink_dob_completion ||
          !!activeDeal.value?.commercialData?.dnb?.bm_l1 ||
          !!activeDeal.value?.commercialData?.experian?.business_match ||
          !!activeDeal.value?.commercialData?.clear
            ?.clear_id_confirm_business ||
          !!activeDeal.value?.commercialData?.clear?.clear_id_confirm_person ||
          serviceHasBeenRun(
            serviceStatuses.value?.sentilink_ssn_completion,
            serviceStatuses.value?.sentilink_dob_completion,
            serviceStatuses.value?.dnb.bm_l1,
            serviceStatuses.value?.experian.business_match
          )
        );
      case "Fraud":
        return (
          !!activeDeal.value?.commercialData?.sentilink ||
          !!activeDeal.value?.commercialData?.socure.fraud ||
          serviceHasBeenRun(
            serviceStatuses.value?.sentilink,
            serviceStatuses.value?.socure.fraud
          )
        );
      case "Cash Flow Analysis":
        return (
          !!activeDeal.value?.commercialData?.ocrolus_books?.length ||
          serviceHasBeenRun(
            serviceStatuses.value?.ocrolus_cfa,
            serviceStatuses.value?.heron
          )
        );
      case "Business Credit":
        return (
          !!activeDeal.value?.business.credits?.length ||
          serviceHasBeenRun(
            serviceStatuses.value?.experian.intelliscore,
            serviceStatuses.value?.experian.fsr,
            serviceStatuses.value?.dnb.fi_l2,
            serviceStatuses.value?.dnb.fi_l3,
            serviceStatuses.value?.dnb.fi_l4,
            serviceStatuses.value?.experian.gdn.company_profile,
            serviceStatuses.value?.experian.gdn.risk_check,
            serviceStatuses.value?.experian.gdn.small_report,
            serviceStatuses.value?.experian.gdn.extended_report,
            serviceStatuses.value?.experian.gdn.canadian_profile_report
          )
        );
      case "Personal Credit":
        return (
          hasPersonalCreditResponse.value || hasPersonalCreditBeenRun.value
        );

      case "Bankruptcies":
        return (
          !!activeDeal.value?.business.bankruptcies?.length ||
          activeDeal.value?.commercialData?.middesk?.review?.tasks.some(
            (task) => task.key === "bankruptcies"
          ) ||
          serviceHasBeenRun(
            serviceStatuses.value?.experian.bankruptcies,
            serviceStatuses.value?.lexis_nexis.bankruptcy_report,
            serviceStatuses.value?.lexis_nexis.bankruptcy_search
          )
        );
      case "Judgements":
        return (
          !!activeDeal.value?.business.judgments?.length ||
          serviceHasBeenRun(
            serviceStatuses.value?.experian.judgments,
            serviceStatuses.value?.lexis_nexis.bankruptcy_report,
            serviceStatuses.value?.lexis_nexis.bankruptcy_search
          )
        );
      case "UCC filings":
        return (
          !!activeDeal.value?.business.uccs?.length ||
          serviceHasBeenRun(serviceStatuses.value?.experian.uccs) ||
          serviceHasBeenRun(
            serviceStatuses.value?.clear?.clear_risk_inform_person_search
          ) ||
          serviceHasBeenRun(
            serviceStatuses.value?.clear?.clear_risk_inform_person_report
          )
        );
      case "Liens":
        return (
          !!activeDeal.value?.business.liens?.length ||
          serviceHasBeenRun(serviceStatuses.value?.experian.liens) ||
          serviceHasBeenRun(
            serviceStatuses.value?.clear["clear_risk_inform_business_report"]
          ) ||
          serviceHasBeenRun(
            serviceStatuses.value?.clear["clear_risk_inform_business_search"]
          )
        );
      case "Social Scoring":
        return (
          !!activeDeal.value?.commercialData?.scorely ||
          serviceHasBeenRun(serviceStatuses.value?.scorely)
        );
      case "Document Verification":
        return (
          !!activeDeal.value?.document_verifications?.length ||
          activeDeal.value?.document_verifications?.some(
            (doc) => doc.service === "inscribe"
          )
        );
      default:
        return false;
    }
  };

  const getLastTimeServiceWasRun = (
    section: keyof typeof UNDERWRITING_ATTRIBUTES_AND_SERVICES_BY_SECTION
  ) => {
    const servicesToCheck =
      UNDERWRITING_ATTRIBUTES_AND_SERVICES_BY_SECTION[section] || [];
    const dates = activeDeal.value.commercialData?.dates;
    const serviceTimes = Object.values(servicesToCheck)?.reduce(
      (acc: Date[], curr: string) => {
        if (dates?.[curr]) {
          const current = new Date();
          const [date, time] = dates?.[curr].split(" ") || ["", ""];
          const [year, month, day] = date
            .split("-")
            .map((number) => parseInt(number));
          const [hour, minute, second] = time
            .split(":")
            .map((number) => parseInt(number));
          current.setUTCFullYear(year, month - 1, day);
          current.setUTCHours(hour, minute, second);
          return [...acc, current];
        }
        return acc;
      },
      []
    );

    serviceTimes?.sort()?.reverse();
    return serviceTimes?.[0];
  };

  const activeDealCategory = computed(() => getDealCategory(activeDeal.value));

  const offers = computed<IOffer[]>(() => getters["applications/offers"]);

  const hasPersonalCreditResponse = computed(() => {
    const bop = activeDeal.value?.commercialData?.experian?.bop || null;
    if (!bop) {
      return false;
    }
    return Object.values(bop).some(
      (value: IBopCommDataResponse[keyof IBopCommDataResponse]) => !!value
    );
  });

  const hasPersonalCreditBeenRun = computed(() => {
    const bop = serviceStatuses.value?.experian?.bop || null;
    if (!bop) {
      return false;
    }
    return Object.values(bop).some(
      (serviceModels: IBopStatuses[keyof IBopStatuses]) =>
        Object.values(serviceModels).some((value) => serviceHasBeenRun(value))
    );
  });

  const approvedOffers = computed<IOffer[]>(
    () => getters["applications/approvedOffers"]
  );

  const approvedOffersGrouped = computed<IOffer[]>(
    () => getters["applications/approvedOffersGrouped"]
  );

  const offersByProductTypes = computed<Record<FinancialProduct, IOffer[]>>(
    () => getters["applications/fundedOffersGrouped"]
  );

  const { currentAppNotes: dealNotes } = storeToRefs(applicationsStore);

  const canEditDealApplicationAndUnderwriting = computed(() => {
    if (activeDealCategory.value !== WORKFLOW_CATEGORY_MARKETPLACE) {
      return true;
    }

    if (isClientUser) {
      return false;
    }

    if (isClientAdmin || isClientUnderwriter) {
      return isClientFunded.value && isUnderwritingEnabled.value;
    }

    return true;
  });

  const canEditDealProgress = computed(() => {
    if (activeDealCategory.value === WORKFLOW_CATEGORY_MARKETPLACE) {
      if (isClientUser) {
        return false;
      }

      if (isClientAdmin || isClientUnderwriter) {
        return !!isClientFunded.value && isUnderwritingEnabled.value;
      }

      return true;
    }
    return true;
  });

  const userAllowedSelfFunding = computed(
    () => isClientUnderwriter || isClientAdmin || isAdmin
  );

  const removePhases = (phases: string[], phasesToRemove: string[]) => {
    return phases.filter(
      (phase) =>
        !phasesToRemove
          .map((ptr) => ptr.toLowerCase())
          .includes(phase.toLowerCase())
    );
  };

  const activeDealPhases = computed(() => {
    if (!activeDeal.value.id) {
      return [];
    }

    const defaultPhases: Record<string, string> = getters["options/phases"];
    const defaultPhasesArray = Object.values(defaultPhases);

    if (isActiveDealDataCapture.value) {
      return [PHASE_APPLICATION];
    }

    if (isActiveDealEquipmentRental.value) {
      if ((isActiveDealSelfFunded.value && isClient) || isLendflowUser) {
        return [
          PHASE_APPLICATION,
          PHASE_UNDERWRITING,
          PHASE_OFFER,
          PHASE_ABSTRACT_APPROVED
        ];
      }
      return [PHASE_APPLICATION, PHASE_UNDERWRITING];
    }

    if (!isActiveDealEquipmentRental.value) {
      if (isClientFunded.value) {
        return removePhases(PHASES_FOR_LENDFLOW_FOR_FUNDING_APPS, [
          PHASE_PLACEMENT
        ]);
      }
      return PHASES_FOR_LENDFLOW_FOR_FUNDING_APPS;
    }

    if (
      userAllowedSelfFunding.value &&
      isActiveDealSelfFunded.value &&
      activeDeal.value.status !== 2
    ) {
      return removePhases(defaultPhasesArray, [
        PHASE_PLACEMENT,
        PHASE_COMPLETE,
        PHASE_OFFER
      ]);
    }

    if (
      !isClientUnderwriter &&
      !isActiveDealEquipmentRental.value &&
      !isClientAdmin
    ) {
      return defaultPhasesArray;
    }

    return Object.keys(defaultPhases).reduce<string[]>(
      (clientUnderwriterPhases, phaseId) =>
        PHASE_IDS_FOR_CLIENT_UNDERWRITER_AND_ER_APPS.includes(phaseId)
          ? [...clientUnderwriterPhases, defaultPhases[phaseId]]
          : clientUnderwriterPhases,
      []
    );
  });

  const currentStatusName = computed(() => {
    const apiPhases: Record<string, string> = getters["options/phases"];
    const statusToConsider =
      activeDeal.value?.death?.previous_status || activeDeal.value.status;

    return statusToConsider ? apiPhases[statusToConsider] : "";
  });

  const cleanBusinessValues = (business: Partial<IBusiness>) => {
    const states = computed<Record<string, string>>(
      () => getters["options/states"]
    );
    if (business.fiscal_year_end) {
      business.fiscal_year_end = getDateFromMonth(business.fiscal_year_end);
    }
    if (business.formation_state) {
      business.formation_state = states.value[business.formation_state];
    }
    business.piis = [];
    business = filterNotNullableValues(business, [
      "formation_state",
      "naics_code",
      "fiscal_year_end"
    ]);
    business = forceNullForEmptyStrings(business);
    return business;
  };

  const getParamsForRetrievingDOTemplates = (
    deal: IApplication,
    search?: string
  ) => {
    const activeStage: IWorkflowStage = getters["workflows/activeStage"];

    const params: Record<string, string | number | string[]> = {
      status: DO_SAVE_TEMPLATE_MODES.publish,
      stage_id: activeStage?.id
    };
    if (search) {
      params.search = search;
    }

    if (isLendflowUser && deal.partner?.client?.id) {
      params.client_id = deal.partner.client.id;
    }

    return {
      applicationId: deal.id,
      params
    };
  };

  return {
    activeDeal,
    isActiveDealSelfFunded,
    isActiveDealCanadian,
    isActiveDealEquipmentRental,
    isActiveDealDataCapture,
    offers,
    approvedOffers,
    approvedOffersGrouped,
    offersByProductTypes,
    dealNotes,
    activeDealCategory,
    canEditDealApplicationAndUnderwriting,
    canEditDealProgress,
    activeDealPhases,
    isStepFinished,
    getLastTimeServiceWasRun,
    currentStatusName,
    authClientSettings,
    isClientFunded,
    isUnderwritingEnabled,
    isWorkflowLive,
    cleanBusinessValues,
    getParamsForRetrievingDOTemplates
  };
};

export const useDealsForActiveBusiness = () => {
  const { getters } = useStore();
  const activeBusinessDeals = computed<IApplication[]>(
    () => getters["businesses/activeBusinessApplications"] || []
  );

  return { activeBusinessDeals };
};

export const useDealsFilters = (
  { dynamicOption }: { dynamicOption: boolean } = { dynamicOption: false }
) => {
  const { getters, commit, dispatch } = useStore();
  const auth = useAuth();
  const isAllDealsFilter = ({ name }: ISmartFilter) =>
    name === SMARTVIEW_ALL_DEALS_NAME;

  const fundersOptionsRef = ref<Record<string, string>>({});
  const fundersOptions = readonly(fundersOptionsRef);
  const scorecardsOptionsRef = ref<Record<string, string>>({});
  const scorecardsOptions = readonly(scorecardsOptionsRef);
  const scorecardGroupsOptionsRef = ref<Record<string, string>>({});
  const scorecardGroupsOptions = readonly(scorecardGroupsOptionsRef);
  const canManageFilters = computed(() => auth.isClientAdmin || auth.isAdmin);

  const client = computed<IClient | null>(
    () => getters["auth/authClientSettings"]
  );

  const filters = computed<ISmartFilter[]>(() =>
    [...getters["applications/filters"]].sort((a, b) => {
      // Smartviews with no sorting preference have a value of 0.
      // The All Smartview should default to first unless it's been manually sorted by the user.
      // In the case it hasn't been sorted yet, make sure it appears above all other unsorted smartviews.
      if (a.sort_order === 0 && b.sort_order === 0) {
        if (isAllDealsFilter(a)) {
          return -1;
        }
        if (isAllDealsFilter(b)) {
          return 1;
        }
      }
      return a.sort_order - b.sort_order;
    })
  );

  const prepareOptionsUpdateHandler = <T>(
    optionsFormatter: (value: T) => Record<string, string>,
    ref: Ref
  ) => {
    return (value: T, type: "search" | "loadmore") => {
      const newOptions = optionsFormatter(value);
      if (type === "search") {
        ref.value = newOptions;
        return;
      }

      ref.value = {
        ...ref.value,
        ...newOptions
      };
    };
  };

  const handleFundersOptionsUpdate = prepareOptionsUpdateHandler<IUser[]>(
    formatUsersForFilterDropdown,
    fundersOptionsRef
  );

  const handleScorecardsOptionsUpdate = prepareOptionsUpdateHandler<
    IScorecard[]
  >(formatScorecardsForFilterDropdown, scorecardsOptionsRef);

  const handleScorecardGroupsOptionsUpdate = prepareOptionsUpdateHandler<
    IScorecardGroup[]
  >(formatScorecardsForFilterDropdown, scorecardGroupsOptionsRef);

  const allDealsFilter = computed<ISmartFilter | undefined>(() =>
    filters.value.find(isAllDealsFilter)
  );

  const visibleFilters = computed(() =>
    filters.value.filter((filter) => filter.is_visible)
  );

  const activeFilterId = computed<number>(
    () => getters["applications/activeFilterId"]
  );

  const [analysts, underwriters, fundingAdvisors] = [
    "applications/analysts",
    "applications/underwriters",
    "applications/fundingAdvisors"
  ].map((getter) =>
    computed(() => {
      const values: Array<IUser> = getters[getter] || [];
      if (dynamicOption) {
        return {
          "-1": "Current user",
          ...formatUsersForFilterDropdown(values)
        };
      }
      return formatUsersForFilterDropdown(values);
    })
  );

  const [clientAnalysts, clientUnderwriters, clientFundingAdvisors] = [
    "applications/clientAnalysts",
    "applications/clientUnderwriters",
    "applications/clientFundingAdvisors"
  ].map((getter) =>
    computed(() => {
      const values: Array<IUser> = getters[getter](
        client.value?.id || DEALS_CLIENT_OPTIONS_UNCATEGORIZED
      );
      if (dynamicOption) {
        return {
          "-1": "Current user",
          ...formatUsersForFilterDropdown(values)
        };
      }
      return formatUsersForFilterDropdown(values);
    })
  );

  const setActiveFilterId = (id: number) => {
    commit("applications/setActiveFilterId", id);
  };

  const defaultSmartviewBuilderModelData: Partial<ISmartFilterData> = {
    deal_statuses: [],
    deal_modes: [],
    application_started_range: [],
    funded_date_range: [],
    workflows: [],
    funding_advisor_ids: [],
    partners: [],
    sources: [],
    underwriter_ids: [],
    states: [],
    master_statuses: [],
    workflow_template_ids: []
  };

  const openSmartviewBuilder = (
    smartview: ISmartFilter | null = null,
    is_available_for_organization = false
  ) => {
    if (smartview) {
      commit("applications/setSmartviewBuilderModel", {
        ...smartview,
        is_visible: smartview.is_visible || 1
      });
      commit("applications/setSmartviewBuilderOpen", true);
      return;
    }
    const data: CreateFilterData = {
      name: "",
      is_available_for_organization,
      is_visible: 1,
      sort_order: 0,
      columns: [],
      data: defaultSmartviewBuilderModelData,
      notifications: []
    };

    commit("applications/setSmartviewBuilderModel", data);
    commit("applications/setSmartviewBuilderOpen", true);
  };

  const closeSmartviewBuilder = () => {
    commit("applications/setSmartviewBuilderOpen", false);
    commit("applications/setSmartviewBuilderModel", null);
  };

  const smartviewBuilderModel = computed<
    (CreateFilterData & UpdateFilterData) | null
  >(() => getters["applications/smartviewBuilderModel"]);

  const isSmartviewBuilderOpen = computed<boolean>(
    () => getters["applications/smartviewBuilderOpen"]
  );

  const deleteFilter = async (id: number): Promise<void> => {
    await dispatch("applications/deleteFilter", id);
  };

  const createFilter = async (data: CreateFilterData): Promise<void> => {
    await dispatch("applications/createFilter", data);
  };

  const toggleOrgSmartViewStatus = async (data: UpdateFilterData) => {
    await dispatch("applications/updateFilter", {
      ...data,
      is_available_for_organization: !data.is_available_for_organization
    });
  };

  const updateFilter = async (data: UpdateFilterData): Promise<void> => {
    await dispatch("applications/updateFilter", {
      ...data,
      is_visible: data.is_visible || 1,
      columns: data.columns || []
    });
  };

  const updateFilterSortOrder = async (data: ISmartFilter[]): Promise<void> => {
    if (!data.length) {
      return;
    }
    const templates = data.map(({ id, sort_order }) => ({
      template_id: id,
      sort_order
    }));
    await dispatch("applications/updateApplicationFilterSortOrder", {
      templates
    });
    await dispatch("applications/getFilters");
  };

  const updateFilterVisibility = async ({
    id,
    is_visible
  }: ISmartFilter): Promise<void> => {
    const templates = filters.value.map((filter) => ({
      template_id: filter.id,
      is_visible: filter.id === id ? is_visible : filter.is_visible ? 1 : 0
    }));
    await dispatch("applications/updateApplicationFilterVisibility", {
      templates
    });
    await dispatch("applications/getFilters");
  };

  const columnFilterGetterMap = computed(() => {
    const map: IColumnFilterGetterMap = {
      statuses: {
        key: "deal_statuses",
        getter: (data): number[] => data?.deal_statuses || []
      },
      stage_names: {
        key: "stage_names",
        getter: (data): string[] => data?.stage_names || []
      },
      stage_management: {
        key: "stage_management",
        getter: (data): string[] => data?.stage_management?.map(String) || []
      },
      deal_modes: {
        key: "deal_modes",
        getter: (data): string[] => data?.deal_modes?.map(String) || []
      },
      master_statuses: {
        key: "master_statuses",
        getter: (data): string[] => data?.master_statuses?.map(String) || []
      },
      states: {
        key: "states",
        getter: (data): string[] => data?.states?.map(String) || []
      },
      sources: {
        key: "sources",
        getter: (data): string[] => data?.sources?.map(String) || []
      },
      partners: {
        key: "partners",
        getter: (data): string[] => data?.partners?.map(String) || []
      },
      created_at_range: {
        key: "application_started_range",
        getter: (data) => data?.application_started_range ?? []
      },
      funded_date_range: {
        key: "funded_date_range",
        getter: (data) => data?.funded_date_range ?? []
      },
      is_business_credit: {
        key: "workflows",
        getter: (data) =>
          (data?.workflows || []).includes("business_credit") ? 1 : 0
      },
      is_data_capture: {
        key: "workflows",
        getter: (data) =>
          (data?.workflows || []).includes("data_capture") ? 1 : 0
      },
      is_equipment_rental: {
        key: "workflows",
        getter: (data) =>
          (data?.workflows || []).includes("equipment_rental") ? 1 : 0
      },
      is_funding: {
        key: "workflows",
        getter: (data) => ((data?.workflows || []).includes("funding") ? 1 : 0)
      },
      is_client_funded: {
        key: "workflows",
        getter: (data) =>
          (data?.workflows || []).includes("internal_funding") ? 1 : 0
      },
      underwriter_ids: {
        key: "underwriter_ids",
        getter: (data): string[] => data?.underwriter_ids?.map(String) || []
      },
      funding_advisor_ids: {
        key: "funding_advisor_ids",
        getter: (data): string[] => data?.funding_advisor_ids?.map(String) || []
      },
      has_offer: {
        key: "has_offer",
        getter: (data) => data?.has_offer || null
      },
      has_bank_statements: {
        key: "has_bank_statements",
        getter: (data) => data?.has_bank_statements || null
      },
      has_bank_data: {
        key: "has_bank_data",
        getter: (data) => data?.has_bank_data || null
      },
      has_placement: {
        key: "has_placement",
        getter: (data) => data?.has_placement || null
      },
      has_plaid_connection: {
        key: "has_plaid_connection",
        getter: (data) => data?.has_plaid_connection || null
      },
      is_signed: {
        key: "is_signed",
        getter: (data) => data?.is_signed || null
      },
      stated_monthly_revenue: {
        key: "stated_monthly_revenue",
        getter: (data): string[] => data?.stated_monthly_revenue || []
      },
      is_discovery_complete: {
        key: "is_discovery_complete",
        getter: (data) => data?.is_discovery_complete || null
      },
      client_underwriter_ids: {
        key: "client_underwriter_ids",
        getter: (data) => data?.client_underwriter_ids || null
      },
      client_funding_advisor_ids: {
        key: "client_funding_advisor_ids",
        getter: (data) => data?.client_funding_advisor_ids || null
      },
      client_tracking_tokens: {
        key: "client_tracking_tokens",
        getter: (data) => data?.client_tracking_tokens || null
      },
      additional_tracking_tokens: {
        key: ADDITIONAL_TRACKING_TOKENS,
        getter: (data) => data?.additional_tracking_tokens || null
      },
      client_analyst_ids: {
        key: "client_analyst_ids",
        getter: (data) => data?.client_analyst_ids || null
      },
      analyst_ids: {
        key: "analyst_ids",
        getter: (data) => data?.analyst_ids || null
      },
      workflow_template_ids: {
        key: "workflow_template_ids",
        getter: (data): string[] =>
          data?.workflow_template_ids?.map(String) || []
      },
      number_of_employees: {
        key: "number_of_employees",
        getter: (data) => data?.number_of_employees || null
      },
      number_of_calls: {
        key: "number_of_calls_range",
        getter: (data) => data?.number_of_calls_range || []
      },
      created_at_call: {
        key: "calls_date_range",
        getter: (data) => data?.calls_date_range || []
      },
      last_call_disposition: {
        key: "last_call_disposition",
        getter: (data) => data?.last_call_disposition || []
      },
      last_call_range: {
        key: "last_call_range",
        getter: (data) => data?.last_call_range || []
      },
      stage_categories: {
        key: "stage_categories",
        getter: (data) => data?.stage_categories || null
      },
      score_card_ids: {
        key: "score_card_ids",
        getter: (data) => data?.score_card_ids || []
      },
      score_card_group_ids: {
        key: "score_card_group_ids",
        getter: (data) => data?.score_card_group_ids || []
      },
      score_card_points_range: {
        key: "score_card_points_range",
        getter: (data) => data?.score_card_points_range || []
      },
      score_card_group_points_range: {
        key: "score_card_group_points_range",
        getter: (data) => data?.score_card_group_points_range || []
      },
      time_in_business_range: {
        key: "time_in_business_range",
        getter: (data) => data?.time_in_business_range || []
      },
      time_in_current_stage_range: {
        key: "time_in_current_stage_range",
        getter: (data) => data?.time_in_current_stage_range || []
      },
      time_to_first_offer_range: {
        key: "time_to_first_offer_range",
        getter: (data) => data?.time_to_first_offer_range || []
      },
      funders_with_offers: {
        key: "funders_with_offers",
        getter: (data) => data?.funders_with_offers || []
      },
      funders_with_placements: {
        key: "funders_with_placements",
        getter: (data) => data?.funders_with_placements || []
      },
      first_placement_range: {
        key: "first_placement_range",
        getter: (data) => data?.first_placement_range || []
      },
      last_placement_range: {
        key: "last_placement_range",
        getter: (data) => data?.last_placement_range || []
      },
      business_time_zones: {
        key: "business_time_zones",
        getter: (data) => data?.business_time_zones || []
      },
      actual_monthly_revenue_range: {
        key: "actual_monthly_revenue_range",
        getter: (data) => data?.actual_monthly_revenue_range || []
      },
      last_updated_offer_statuses: {
        key: "last_updated_offer_statuses",
        getter: (data) => data?.last_updated_offer_statuses || []
      },
      primary_owner_actual_credit_score_range: {
        key: "primary_owner_actual_credit_score_range",
        getter: (data) => data?.primary_owner_actual_credit_score_range || []
      },
      primary_owner_stated_credit_scores: {
        key: "primary_owner_stated_credit_scores",
        getter: (data) => data?.primary_owner_stated_credit_scores || []
      },
      funded_amount_range: {
        key: "funded_amount_range",
        getter: (data) => data?.funded_amount_range || []
      },
      latest_score_card_statuses: {
        key: "latest_score_card_statuses",
        getter: (data) => data?.latest_score_card_statuses || []
      }
    };

    return map;
  });

  const dealsColumnSmartFiltersKeyMap: Record<SmartFilterColumn, string> = {
    actual_monthly_revenue_range: "business.actual_monthly_revenue",
    additional_tracking_tokens: "tracking_tokens",
    analyst_ids: "analyst_full_name",
    application_started_range: "created_at",
    business_time_zones: "business.address.time_zone",
    calls_date_range: "number_of_call_sessions",
    client_analyst_ids: "client_analyst_full_name",
    client_funding_advisor_ids: "client_advisor_full_name",
    client_tracking_tokens: CLIENT_TRACKING_TOKENS,
    client_underwriter_ids: "client_underwriter_full_name",
    deal_modes: "deal_mode",
    deal_statuses: "status",
    first_placement_range: "first_placement_datetime",
    funded_amount_range: "funded_amount",
    funded_date_range: "accepted_offer.funded_date",
    funders_with_offers: "funders_with_offers",
    funders_with_placements: "funders_with_placements",
    funding_advisor_ids: "advisor_full_name",
    has_bank_data: "has_bank_data",
    has_bank_statements: "bank_statements_count",
    has_offer: "offer_count",
    has_placement: "placements_count",
    has_plaid_connection: "plaid_connection",
    is_discovery_complete: "is_discovery_complete",
    is_signed: "is_signed",
    last_call_disposition: "last_call_session.call_disposition",
    last_call_range: "last_call_range",
    last_placement_range: "last_placement_datetime",
    last_updated_offer_statuses: "last_updated_offer_status",
    latest_score_card_statuses: "score_card_result.status",
    master_statuses: "data_orchestration_log.master_status",
    number_of_calls_range: "number_of_call_sessions",
    number_of_employees: "business.number_of_employees_id",
    partners: "partner.client.name",
    primary_owner_actual_credit_score_range:
      "personal_information.actual_credit_score",
    primary_owner_stated_credit_scores: "personal_information.credit_score",
    score_card_ids: "score_card_name",
    score_card_group_ids: "score_card_group_name",
    score_card_points_range: "score_card_result.points",
    score_card_group_points_range: "score_card_group_result.points",
    sources: "source",
    stage_categories: "stage_category",
    stage_management: "stage_management",
    stage_names: "stage_name",
    stated_monthly_revenue: "stated_monthly_revenue",
    states: "business.address.state",
    time_in_business_range: "time_in_business",
    time_in_current_stage_range: "time_in_current_stage",
    time_to_first_offer_range: "time_to_first_offer",
    underwriter_ids: "underwriter_full_name",
    workflow_template_ids: "workflow_name",
    workflows: "is_business_credit"
  };

  const removeUnusedColumnsAndData = (
    filter: UpdateFilterData,
    dealColumnsKeys: string[]
  ): UpdateFilterData => ({
    ...filter,
    columns: (filter?.columns || []).filter((filterColumn: string) =>
      dealColumnsKeys.includes(filterColumn)
    ),
    data: Object.fromEntries(
      Object.entries(filter?.data || {}).filter(([key]) => {
        return key in dealsColumnSmartFiltersKeyMap;
      })
    )
  });

  const activeSmartviewCategory = computed<SmartviewCategory>(
    () => getters["applications/activeSmartviewCategory"]
  );

  const replaceDynamicFilterPlaceholders = (
    filters: ISmartFilterData | undefined
  ) => {
    if (!filters) return;

    type RoleFilterKeys =
      | "analyst_ids"
      | "underwriter_ids"
      | "funding_advisor_ids"
      | "client_analyst_ids"
      | "client_underwriter_ids"
      | "client_funding_advisor_ids";

    const FILTERS_TO_UPDATE = [
      "analyst_ids",
      "underwriter_ids",
      "funding_advisor_ids",
      "client_analyst_ids",
      "client_underwriter_ids",
      "client_funding_advisor_ids",
      "application_started_range",
      "funded_date_range",
      "calls_date_range",
      "number_of_calls_range",
      "last_call_range",
      "first_placement_range",
      "last_placement_range"
    ];

    const permissions: Record<
      (typeof FILTERS_TO_UPDATE)[number],
      keyof typeof auth
    > = {
      analyst_ids: "isAnalyst",
      underwriter_ids: "isUnderwriter",
      funding_advisor_ids: "isFundingAdvisor",
      client_analyst_ids: "isClientAnalyst",
      client_underwriter_ids: "isClientUnderwriter",
      client_funding_advisor_ids: "isClientFundingAdvisor"
    };

    return Object.entries(filters).reduce(
      (acc: ISmartFilterData, [key, value]) => {
        if (!FILTERS_TO_UPDATE.includes(key as keyof ISmartFilterData)) {
          acc[key as keyof ISmartFilterData] = value;
          return acc;
        }
        const currentFilter = (value ?? []) as string[];
        const indexToReplace = currentFilter?.indexOf("-1");

        const isDynamicFilterValPlaceholder =
          indexToReplace !== -1 &&
          auth[permissions[key]] &&
          auth.currentUser?.id;

        const isDynamicDateFilter = Object.values(DynamicDate).includes(
          currentFilter?.[0] as DynamicDate
        );

        let copyOfArrFilter = [...currentFilter];

        if (isDynamicFilterValPlaceholder) {
          copyOfArrFilter[indexToReplace] = String(auth.currentUser.id);
        }

        if (isDynamicDateFilter) {
          copyOfArrFilter = getShortcutDate(currentFilter[0]).map((date) =>
            formatDateCustom(date, "MM/dd/yyyy", true)
          );
        }

        acc[key as RoleFilterKeys] = isDynamicFilterValPlaceholder
          ? copyOfArrFilter
          : copyOfArrFilter.filter((id) => id !== "-1");
        return acc;
      },
      {} as ISmartFilterData
    );
  };

  return {
    canManageFilters,
    filters,
    columnFilterGetterMap,
    deleteFilter,
    createFilter,
    updateFilter,
    removeUnusedColumnsAndData,
    replaceDynamicFilterPlaceholders,
    activeFilterId,
    setActiveFilterId,
    allDealsFilter,
    visibleFilters,
    updateFilterSortOrder,
    closeSmartviewBuilder,
    openSmartviewBuilder,
    smartviewBuilderModel,
    isSmartviewBuilderOpen,
    updateFilterVisibility,
    toggleOrgSmartViewStatus,
    dealsColumnSmartFiltersKeyMap,
    defaultSmartviewBuilderModelData,
    activeSmartviewCategory,
    clientFundingAdvisors,
    clientAnalysts,
    clientUnderwriters,
    fundingAdvisors,
    analysts,
    underwriters,
    fundersOptions,
    handleFundersOptionsUpdate,
    scorecardsOptions,
    handleScorecardsOptionsUpdate,
    scorecardGroupsOptions,
    handleScorecardGroupsOptionsUpdate
  };
};

export const useDealFiles = (dealId?: string) => {
  const { activeDeal } = useDealsBase();
  const { getters, dispatch } = useStore();

  const { fetchWrapper: getDealFiles } = usePromiseWrapper(async () => {
    const appId = dealId ?? activeDeal.value.id;
    if (!appId) {
      return;
    }
    if (loadingServices.value["applications/getFiles"]) {
      return;
    }
    loadingServices.value["applications/getFiles"] = true;
    try {
      await dispatch("applications/getFiles", appId);
    } finally {
      loadingServices.value["applications/getFiles"] = false;
    }
  });

  const files = computed<IFile[]>(() => getters["applications/files"]);
  const isFilesLoading = computed(
    () => loadingServices.value["applications/getFiles"]
  );

  return { getDealFiles, files, isFilesLoading };
};
