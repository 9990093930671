import { defineStore } from "pinia";
import applicationsApiService from "@/services/modules/applications";
import type {
  AddApplicationNotePayload,
  EditApplicationNotePayload,
  IApplicationNote,
  IDataOrchestrationLog
} from "@/models/applications";
import type { EXTRA_DEAL_VIEW_REQUESTS } from "@/helpers/constants/deals";

interface UseApplicationsState {
  dataOrchestrationLog: IDataOrchestrationLog | null;
  currentAppNotes: IApplicationNote[];
  canSeeNotes: boolean;
  shouldFetch: Record<(typeof EXTRA_DEAL_VIEW_REQUESTS)[number], boolean>;
}

const getDefaultState = (): UseApplicationsState => ({
  dataOrchestrationLog: null,
  currentAppNotes: [],
  canSeeNotes: false,
  shouldFetch: {
    notes: true,
    businessNotes: true,
    offers: true
  }
});

const useApplicationsStore = defineStore("applications", {
  state: () => getDefaultState(),
  getters: {
    activeDataOrchestrationLog: ({ dataOrchestrationLog }) =>
      dataOrchestrationLog,
    shouldFetchNotes: ({ shouldFetch }) => shouldFetch.notes,
    shouldFetchBusinessNotes: ({ shouldFetch }) => shouldFetch.businessNotes,
    shouldFetchOffers: ({ shouldFetch }) => shouldFetch.offers
  },
  actions: {
    async getDataOrchestrationLog({ appId }: { appId: string }) {
      this.dataOrchestrationLog = null;
      const logsData: IDataOrchestrationLog[] =
        await applicationsApiService.getDataOrchestrationLogs(appId);

      if (!logsData.length) {
        return;
      }

      const activeLogId = logsData[0].id;

      if (!activeLogId) {
        return;
      }

      const logData = await applicationsApiService.getDataOrchestrationLog(
        appId,
        activeLogId
      );

      this.dataOrchestrationLog = logData;
    },
    updateDataOrchestrationLog(log: IDataOrchestrationLog) {
      this.dataOrchestrationLog = log;
    },
    async updateDataOrchestrationMasterStatus(payload: {
      appId: string;
      logId: string;
      master_status: number;
    }) {
      await applicationsApiService.updateDataOrchestrationMasterStatus(
        payload.appId,
        payload.logId,
        payload.master_status
      );
    },
    updateFetchServiceStatus(
      services: (typeof EXTRA_DEAL_VIEW_REQUESTS)[number][],
      status: boolean
    ) {
      services.forEach((service) => (this.shouldFetch[service] = status));
    },
    async getApplicationNotes(params: {
      applicationId: string;
      types?: string[];
    }) {
      const notes = await applicationsApiService.getApplicationNotes(
        params.applicationId,
        params
      );
      this.currentAppNotes = notes;
    },
    async addApplicationNote({
      applicationId,
      note,
      stage,
      visibility_scope
    }: AddApplicationNotePayload) {
      const notes = await applicationsApiService.addApplicationNote(
        applicationId,
        note,
        stage,
        visibility_scope
      );
      this.currentAppNotes.unshift(notes);
    },
    async editApplicationNote({
      applicationId,
      note,
      noteId,
      stage,
      visibility_scope
    }: EditApplicationNotePayload) {
      const response = await applicationsApiService.updateApplicationNote(
        applicationId,
        noteId,
        note,
        stage,
        visibility_scope
      );
      this.currentAppNotes = this.currentAppNotes.filter(
        (note) => note.id !== noteId
      );
      this.currentAppNotes = [response, ...this.currentAppNotes];
    },
    async deleteApplicationNote({
      applicationId,
      noteId
    }: {
      applicationId: string;
      noteId: number;
    }) {
      await applicationsApiService.deleteApplicationNote(applicationId, noteId);
      this.currentAppNotes = this.currentAppNotes.filter(
        (note) => note.id !== noteId
      );
    }
  }
});

export default useApplicationsStore;
