import { useStore } from "vuex";
import { useActiveStage } from "./workflow";

import type {
  IWorkflowTemplateDefinition,
  WorkflowBlockDefinition
} from "@/models/workflows";
import type {
  IIndustry,
  ISubIndustry,
  INumberOfEmployee,
  SubIndustryWithParent,
  RiskTierOption
} from "@/models/options";
import { computed } from "vue";
import type { Nullish, IDashboardSettings } from "@/models/common";

export const useWorkflowTemplatesDefinitionOption = () => {
  const { getters } = useStore();
  const definition: IWorkflowTemplateDefinition =
    getters["options/workflowTemplatesDefinition"];
  return { definition };
};

export const useWorkflowTemplatesStagesOption = () => {
  const { definition } = useWorkflowTemplatesDefinitionOption();
  const { activeStage } = useActiveStage();
  const activeStageBlockOptions = computed<WorkflowBlockDefinition[]>(
    () =>
      definition.stages.find((stage) => stage.type === activeStage.value?.type)
        ?.blocks || []
  );
  return {
    stages: definition.stages,
    activeStageBlockOptions
  };
};

export const useIndustryOptions = () => {
  const { getters } = useStore();
  const industryTypesOptions = computed<Record<string, string>>(() =>
    getters["options/industryTypes"]().reduce(
      (industryTypesAcc: Record<string, string>, industry: IIndustry) => {
        industryTypesAcc[industry.id] = industry.label;
        return industryTypesAcc;
      },
      {}
    )
  );

  const industryTypeLabel = (
    industryId: number | null,
    defaultTo: string | null = "-"
  ): string | null => {
    return industryId
      ? getters["options/industryType"](industryId)?.label || defaultTo
      : defaultTo;
  };

  const industryAndSubIndustryTypes = computed<SubIndustryWithParent[]>(() =>
    getters["options/subIndustryTypes"]()
  );
  const subIndustryTypesOptions = (
    industryId: number | Nullish
  ): Record<string, string> => {
    if (industryId === null || industryId === undefined) {
      return {};
    }
    return getters["options/subIndustryTypes"](industryId).reduce(
      (
        subIndustryTypesAcc: Record<string, string>,
        subIndustry: ISubIndustry
      ) => {
        subIndustryTypesAcc[subIndustry.id] = subIndustry.label;
        return subIndustryTypesAcc;
      },
      {}
    );
  };

  const subIndustryTypeLabel = (
    industryId: number | null | undefined,
    subIndustryId: number | null | undefined,
    defaultTo: string | null = "-"
  ): string | null => {
    let label = defaultTo;

    if (industryId && subIndustryId) {
      label = getters["options/subIndustryType"](
        industryId,
        subIndustryId
      )?.label;
    }

    return label;
  };

  return {
    industryTypesOptions,
    industryTypeLabel,
    subIndustryTypesOptions,
    subIndustryTypeLabel,
    industryAndSubIndustryTypes
  };
};

export const useNumberOfEmployees = () => {
  const { getters } = useStore();

  const numberOfEmployeesOptions = computed<INumberOfEmployee[]>(() =>
    getters["options/numberOfEmployees"]()
  );

  const numberOfEmployeesOptionsLabels = computed(() =>
    numberOfEmployeesOptions.value.flatMap(({ label }) => label)
  );

  const numberOfEmployeeIdByIndex = (index: number) => {
    return numberOfEmployeesOptions.value[index].id;
  };

  const numberOfEmployeeIndexById = (id: number | undefined) => {
    const index = numberOfEmployeesOptions.value.findIndex(
      (item) => item.id === id
    );
    return index >= 0 ? index : null;
  };

  return {
    numberOfEmployeesOptionsLabels,
    numberOfEmployeeIdByIndex,
    numberOfEmployeeIndexById
  };
};

export const useStatuses = () => {
  const { getters } = useStore();

  const apiStatuses = computed<Record<string, string>>(
    () => getters["options/phases"]
  );
  return {
    apiStatuses
  };
};

export const useLocalStorageSetting = <
  TKey extends keyof IDashboardSettings,
  TValue = Required<IDashboardSettings>[TKey]
>(
  key: TKey,
  defaultVal: TValue
) => {
  const { getters, commit } = useStore();

  const setting = computed<TValue>({
    get() {
      const options: IDashboardSettings | undefined =
        getters["options/settings"];
      const val = options?.[key];
      if (val === undefined) {
        return defaultVal;
      }
      return val as TValue;
    },
    set(value) {
      commit("options/setSettings", { key, value });
    }
  });

  return setting;
};

export const useRiskTiers = () => {
  const { getters } = useStore();

  const riskTiers = computed<RiskTierOption[]>(
    () => getters["options/riskTiers"]
  );

  const riskTiersPiorityMap = computed<Record<string, number>>(() =>
    riskTiers.value.reduce<Record<string, number>>(
      (acc: Record<string, number>, riskTier: RiskTierOption) => {
        acc[riskTier.name] = riskTier.priority;
        return acc;
      },
      {}
    )
  );

  const sortRiskTiers = (a: string, b: string) => {
    return riskTiersPiorityMap.value[a] - riskTiersPiorityMap.value[b];
  };

  return { sortRiskTiers, riskTiersPiorityMap };
};
